import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material";
import { TimeFilter } from "components/TimeFilter/TimeFilter";
import { toastError } from "@3edges/utils/dist/toastify";
import { useCookie } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import humanizeDuration from "humanize-duration";
import axios from "lib/axios/axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import
    {
        Description,
        EmptyList,
        FilerSpan,
        FilterInput,
        HeaderTableContainer,
        HeaderTableFilterContainer,
        LoaderContainer,
        LoadingGradient,
        MainContainer,
        StyledTable
    } from "./styled";
import { ICreateQueryDate, ICreateQueryPagination, IGetAllTransactionsResponseData, INiamDataLoad } from "./types";

interface TransactionsHistoryProps {
    apiServerUrl: string
    isApiServerConnected: boolean
}

export function TransactionsHistory ({ apiServerUrl, isApiServerConnected }: TransactionsHistoryProps): React.ReactElement {
    const { t } = useTranslation();
    const { getAccessTokenCookie } = useCookie();
    const accessToken = getAccessTokenCookie();

    const [error, setError] = useState<Error>(null);
    const [loading, setloading] = useState<boolean>(true);
    const [transactions, setTransactions] = useState<INiamDataLoad[]>([]);

    const [pagination, setPagination] = useState<ICreateQueryPagination>({
        limit: 10,
        offset: 0
    });

    const [searchWord, setSearchWord] = useState<string>(null);

    const [date, setDate] = useState<ICreateQueryDate>({
        fromDate: "0",
        toDate: Date.now().toString()
    });

    const [totalCount, setTotalCount] = useState<number>(0);

    const fetchData = (): void => {
        setloading(true)

        const queryData = {
            serverURL: apiServerUrl,
            params: {
                date: {
                    fromDate: date.fromDate,
                    toDate: date.toDate,
                },
                searchWord,
                pagination: {
                    limit: pagination.limit,
                    offset: pagination.offset
                }
            }
        }


        axios.post<IGetAllTransactionsResponseData>(
            `${REACT_ENV.REACT_APP_DATALOADER_URL}/transactions`,
            { ...queryData },
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${accessToken}`
                }
            },
        ).then((res) => {
            const { data, count } = res.data

            setTransactions(data);
            setTotalCount(count);
        })
        .catch((error_) => {
            setError(error_);
        })
        .finally(() => {
            setloading(false);
        });
    };


    const getCurrentPage = (): number => {
        return Math.floor(pagination.offset / pagination.limit)
    }

    useEffect(() => {
        fetchData();
    }, [pagination, searchWord, date]);

    if (error) {
        toastError(error.message);
    }

    if(!isApiServerConnected) {
        return (
            <MainContainer>
                <Box
                sx={{
                    margin: "auto auto",
                }}
            >
                    <Description>{t("transactions:not.connected.error")}</Description>
                </Box>
            </MainContainer>
        )
    }

    return (
        <MainContainer>
            <HeaderTableContainer>
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={getCurrentPage()}
                    onPageChange={(e, page) => {
                        setPagination({ limit: pagination.limit, offset: page * pagination.limit });
                    }}
                    rowsPerPage={pagination.limit}
                    onRowsPerPageChange={(event) => {
                        setPagination({ offset: 0, limit: Number(event.target.value) });
                    }}
                    labelRowsPerPage={t("filters:transactions.per.page")}
                    rowsPerPageOptions={[5, 10, 25]}
                    sx={{
                        paddingLeft: "0",
                        marginLeft: "12px"
                    }}
                />
            </HeaderTableContainer>

            <HeaderTableFilterContainer>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: "14px" }}>
                    <FilterListIcon sx={{ margin: "auto 4px auto auto" }} />

                    <FilerSpan> {t("filters:word.filer")} </FilerSpan>

                    <FilterInput
                        id="outlined-basic"
                        placeholder={t("filters:word.filer.placeholder")}
                        value={searchWord}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchWord(event.target.value);
                        }}
                    />
                </Box>

                <TimeFilter
                    setDate={setDate}
                />
            </HeaderTableFilterContainer>

            {loading
                ? (
                    <LoaderContainer>
                        <LoadingGradient />
                    </LoaderContainer>
                )
                : transactions?.length <= 0
                    ? (
                        <EmptyList>
                            {t("transactions:no.transactions")}
                        </EmptyList>
                    )

                    : (
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="left-aligned">
                                        {t("transactions:transaction.transactionID")}
                                    </TableCell>

                                    <TableCell>
                                        {t("transactions:transaction.userID")}
                                    </TableCell>

                                    <TableCell>
                                        {t("transactions:transaction.type.name")}
                                    </TableCell>

                                    <TableCell>
                                        {t("transactions:transaction.mode")}
                                    </TableCell>

                                    <TableCell>
                                        {t("transactions:transaction.nb.rows")}
                                    </TableCell>

                                    <TableCell>
                                        {t("transactions:transaction.date")}
                                    </TableCell>

                                    <TableCell>
                                        {t("transactions:transaction.duration")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {transactions?.map((transaction) => (
                                    <TableRow key={transaction.TransactionID}>
                                        <TableCell className="left-aligned">
                                            {transaction.TransactionID}
                                        </TableCell>

                                        <TableCell>
                                            {transaction.UserID}
                                        </TableCell>

                                        <TableCell>
                                            {transaction.TypeName}
                                        </TableCell>

                                        <TableCell>
                                            {transaction.Mode}
                                        </TableCell>

                                        <TableCell>
                                            {transaction.NBRows}
                                        </TableCell>

                                        <TableCell>
                                            {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).format(Number(transaction.DateTime))}
                                        </TableCell>

                                        <TableCell>
                                            {humanizeDuration(Number(transaction.Duration), { round: true })}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledTable>
                    )}
        </MainContainer>
    );
}
